<template>
  <router-link :to="agent.web_url" v-if="agent" style="text-decoration: none">
    <div class="card--listing card--agent">
      <div class="card-body">
        <div class="wrapper--info">
          <div class="d-flex justify-content-center">
            <img
              :src="agent.photo_profile"
              v-if="agent.photo_profile"
              :alt="agent.full_name"
              class="agent--img"
            />
          </div>
          <div class="w-100 text-center mt-2">
            <h3 class="agent--name">
              {{ agent.full_name }}
            </h3>
            <div class="agent--phone" v-if="agent.phone">
              <i class="ion-android-call mr-2"></i>
              {{ agent.phone }}
            </div>
            <div class="agent--email">
              {{ agent.email }}
            </div>
            <div>
              <span class="agent--job">
                {{ agent.job_title }}
              </span>
              <span v-if="agent.job_title && agent.company_name"> - </span>
              <span class="agent--company">
                {{ agent.company_name }}
              </span>
            </div>
            <div class="agent--reg-no">{{ $t('general.agentRegNo') }} {{ agent.agent_reg_no }}</div>
            <div
              class="d-flex justify-content-center align-center flex-wrap"
              v-if="agent.social_medias && agent.social_medias.length > 0"
              @click="stopPropagation"
            >
              <div v-for="(socmed, index) in agent.social_medias" :key="`social-media-${index}`">
                <a :href="socmed.url" target="_blank" class="social--media">
                  <img
                    src="@/assets/img/icons/instagram-2.svg"
                    v-if="socmed.name === 'instagram'"
                    alt="Instagram"
                  />
                  <img
                    src="@/assets/img/icons/facebook-2.svg"
                    v-else-if="socmed.name === 'facebook'"
                    alt="Facebook"
                  />
                  <img
                    src="@/assets/img/icons/tiktok-2.svg"
                    v-else-if="socmed.name === 'tiktok'"
                    alt="Tiktok"
                  />
                  <div>{{ socmed.name[0].toUpperCase() + socmed.name.substring(1) }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    agent: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {
    stopPropagation(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.card-body {
  padding: 1rem;
}
.card--agent {
  min-height: 320px;
  @media #{$phones} {
    min-height: 300px;
  }
}
.agent--img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border: solid 1px $color-gray-1;
  border-radius: 50%;
  @media #{$phones} {
    width: 80px;
    height: 80px;
  }
}
.agent--name {
  font-family: Poppins-SemiBold;
  font-size: $font-xl;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-md;
  }
}
.agent--phone {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-rentfix-1;
  @media #{$phones} {
    font-size: $font-sm;
  }
}
.agent--email {
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-sm;
  }
}
.agent--job {
  font-size: $font-sm;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.agent--company {
  font-size: $font-sm;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.agent--reg-no {
  color: $color-text-black;
  margin-top: 24px;
  @media #{$phones} {
    font-size: $font-sm;
    margin-top: 16px;
  }
}
.social--media {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-top: 4px;
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 4px;
  }
}
</style>
